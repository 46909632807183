import {
    forum_tool,
    forum
    ,
  } from "../../js/path";
//   import _ from 'lodash';
  
  export default {
    data() {
      return {
        pageNo: null,
        status: false, 
        forumLists: [],
        fields: [{
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: 'forum_id',
            label: 'Forum Name'
          },
          {
            key: "domain_name",
            label: "Domain Name",
          },
          {
            key: "forum_logo",
            label: "Forum Logo",
          },
          {
            key: 'forum_banner',
            label: 'Forum Banner Image'
          },
          {
            key: "settings",
          },
          {
            key: "edit",
          }, 
        ],                                                                                                                                                                                                                                                                                                                              
        form: {
            forum_id: "",
            domain_name: "",
        },
        logo_image: "",
        banner_image: "",
        edit: {
            logo_image: "",
            banner_image: "",
        },
        tableData: [],
        activeTab: "all",
        key: 0,
        currentPage: 1,
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,
      };
    },
    methods: {
      settings(id, forum_id) {
        this.$router.push("/forum-settings/" + id + "/" + forum_id);
      },
      stateStore(id,forum_id) {
        this.$store.commit("setData",{forum_id:forum_id,forum_tool_id:id});
        this.$router.push("/forum-settings/"+id+"/"+forum_id)
      },
      
    //   slugify() {
    //     this.forum_name = this.form.name;
    //     if (this.forum_name) {
    //       const slug_generate = this.forum_name
    //         .toString()
    //         .trim()
    //         .toLowerCase()
    //         .replace(/\s+/g, "-")
    //         .replace(/[^\w-]+/g, "")
    //         .replace(/--+/g, "-")
    //         .replace(/^-+/, "");
    //       this.form.link_name = slug_generate;
    //     } else {
    //       this.form.link_name = null;
    //     }
    //   },
    //   changeForumType(val) {
    //     if (val == "partner") {
    //       if (this.partner.length <= 0) {
    //         this.fetchPartner();
    //       }
    //     }
    //   },
  
      
    //   searchFor() {
    //     if (this.filter.length > 0) {
    //       this.fetchData(this.activeTab);
    //     } else {
    //       this.fetchData(this.activeTab);
    //     }
    //   },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData() {
        let url = null; 
        // if (txt == "trash") {
        //   this.activeTab = txt;
        //   url = forum.forumUrl + "?filter=" + txt;
        // } 
        // else if (txt == "all") {
        //   this.activeTab = txt;
        //   url = forum_tool.forumToolUrl + "?filter=" + txt;
        // }
        // url += this.params;
        // this.getUrl();
        this.$store.commit("loader/updateStatus", true);
        url = forum_tool.forumToolUrl
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
    //   getLink(linkName) {
    //     window.open(this.$hostLink + '/forums/' + linkName);
    //   },
      readFile(e, txt) {
        if (txt == "logo_image") {
          this.logo_image = e.target.files[0];
          if (this.logo_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded File is More than 2MB"
            });
            this.$refs.logo_image.reset();
            this.logo_image = '';
            // this.edit.knowledge_academy_banner_image_url = '';
            // this.knowledge_academy_banner_image = '';
            return false;
          } 
          return true;
        } else if (txt == "banner_image") {
          this.banner_image = e.target.files[0];
          if (this.banner_image.size > 2 * 1000 * 1000) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Uploaded File is More than 2MB"
            });
            this.$refs.banner_image.reset();
            this.banner_image = '';
            return false;
          }
          return true;
        }
      },
      async fetchForumTool(id, forum_id) {
        this.$store.commit("loader/updateStatus", true);
        let url = null;
        if (this.$route.name == "edit-forum-tool") {
          url = forum_tool.forumToolUrl + "/" + id;
        } else {
          url = forum_tool.forumToolUrl + "/" + id + "/" + forum_id;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.forum_id != "" && responseData.forum_id != null) {
            this.form.forum_id = responseData.forum_id;
          }
          if (responseData.domain_name != "" && responseData.domain_name != null) {
            this.form.domain_name = responseData.domain_name;
          }
          if (responseData.logo_image != "" && responseData.logo_image != null) {
            this.form.logo_image = responseData.logo_image;
          }
          if (responseData.banner_image != "" && responseData.banner_image != null) {
            this.form.banner_image = responseData.banner_image;
          }
          this.edit.logo_image = responseData.logo_image;
          this.edit.banner_image = responseData.banner_image;
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   getUrl() {
    //     var url = new URL(window.location);
    //     if (this.activeTab == null || this.activeTab == "") {
    //       this.activeTab = "all";
    //     }
    //     (url.searchParams.has('filter') ? url.searchParams.set('filter', this.activeTab) : url.searchParams.append('filter', this.activeTab));
    //     if (this.filter != null && this.filter != "") {
    //       (url.searchParams.has('search') ? url.searchParams.set('search', this.filter) : url.searchParams.append('search', this.filter));
    //     } else {
    //       url.searchParams.delete('search');
    //     }
    //     if (this.currentPage != null && this.currentPage != "") {
    //       (url.searchParams.has('page') ? url.searchParams.set('page', this.currentPage) : url.searchParams.append('page', this.currentPage));
    //     } else {
    //       url.searchParams.set('page', 1);
    //     }
    //     url.search = url.searchParams;
    //     url = url.toString();
    //     history.pushState({}, null, url);
    //   },
    //   async updateStatus(id) {
    //     this.$store.commit("loader/updateStatus", true);
    //     try {
    //       let index = this.tableData.data.findIndex((e) => e.id === id);
    //       const url = forum.statusUpdate + "/" + id;
    //       const data = await this.postRequest(url, {
    //         is_active: !this.tableData.data[index].is_active,
    //       });
    //       if (data.status) {
    //         const responseData = data.response;
    //         this.tableData.data[index] = responseData;
    //         this.$store.commit("toast/updateStatus", {
    //           status: true,
    //           icon: "success",
    //           title: data.message,
    //         });
    //       }
    //       this.key += 1;
    //     } catch (err) {
    //       this.$store.commit("toast/updateStatus", {
    //         status: true,
    //         icon: "error",
    //         title: err.data ? err.data.message : "Please try again!",
    //       });
    //     }
    //     this.$store.commit("loader/updateStatus", false);
    //   },
    //   async deleteForum(id) {
    //     this.$store.commit("loader/updateStatus", true);
    //     try {
    //       let index = this.tableData.data.findIndex((e) => e.id === id);
    //       const url = forum.forumUrl + "/" + id;
    //       const data = await this.postRequest(url, {
    //         _method: "DELETE",
    //       });
    //       if (data.status) {
    //         this.tableData.data.splice(index, 1);
    //         this.$store.commit("toast/updateStatus", {
    //           status: true,
    //           icon: "success",
    //           title: data.message,
    //         });
    //       }
    //     } catch (err) {
    //       this.$store.commit("toast/updateStatus", {
    //         status: true,
    //         icon: "error",
    //         title: err.data ? err.data.message : "Please try again!",
    //       });
    //     }
    //     this.$store.commit("loader/updateStatus", false);
    //   },
    //   async restoreForum(id) {
    //     this.$store.commit("loader/updateStatus", true);
    //     try {
    //       let index = this.tableData.data.findIndex((e) => e.id === id);
    //       const url = forum.restoreForum + "/" + id;
    //       const data = await this.postRequest(url);
    //       if (data.status) {
    //         this.tableData.data.splice(index, 1);
    //         this.$store.commit("toast/updateStatus", {
    //           status: true,
    //           icon: "success",
    //           title: data.message,
    //         });
    //       }
    //     } catch (err) {
    //       this.$store.commit("toast/updateStatus", {
    //         status: true,
    //         icon: "error",
    //         title: err.data ? err.data.message : "Please try again!",
    //       });
    //     }
    //     this.$store.commit("loader/updateStatus", false);
    //   },
    async fetchForum() {
      const url = forum.fetchAllActiveForum;
      const data = await this.getRequest(url);
      if (data.status) {
        const responseData = data.response;
        this.forumLists = responseData.data;
      }
    },
    async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields"
            });
            return false;
          }
          let url = forum_tool.forumToolUrl;
          let dataAppend = new FormData();
          if (this.logo_image) {
            dataAppend.append("logo_image", this.logo_image);
          }
          if (this.banner_image) {
            dataAppend.append("banner_image", this.banner_image);
          }
          for (var key in this.form) {
            dataAppend.append(key, this.form[key]);
          }
        //   if (this.image_name_indonesia) {
        //     dataAppend.append("image_name_indonesia", this.image_name_indonesia);
        //   }

          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/forum-tool");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
  
      async updateData(id) {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields"
            });
            return false;
          }
          let url = forum_tool.forumToolUrl + "/" + id;
          let dataAppend = new FormData();
          dataAppend.append("forum_id", this.form.forum_id);
          dataAppend.append("domain_name", this.form.domain_name);
          dataAppend.append("logo_image", this.logo_image);
          dataAppend.append("banner_image", this.banner_image);
          dataAppend.append("_method", "put");
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/forum-tool");
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    //   tabActive() {
    //     if (this.activeTab == "trash") {
    //       if (this.table_header.includes("delete")) {
    //         let index = this.fields.findIndex((item) => item.key == "delete");
    //         this.fields[index] = {
    //           key: "restore",
    //         };
    //         let table_index = this.table_header.findIndex((item) => item == "delete");
    //         this.table_header[table_index] = "restore";
  
    //       } else {
    //         this.table_header.push("restore");
    //         this.fields.push({
    //           key: "restore",
    //         });
    //       }
  
    //       if (!this.can("restore-forum")) {
    //         let index = this.fields.findIndex((item) => item.key == "restore");
    //         if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //             (item) => item == "restore"
    //           );
    //           this.table_header.splice(table_index, 1);
    //         }
    //       }
  
    //       let index = this.fields.findIndex((item) => item.key == "edit");
    //       if (index !== -1) {
    //         this.fields.splice(index, 1);
    //         let table_index = this.table_header.findIndex(
    //           (item) => item == "edit"
    //         );
    //         this.table_header.splice(table_index, 1);
    //       }
    //     } else {
    //       if (this.table_header.includes("restore")) {
    //         if (!this.can("edit-forum")) {
    //           this.fields[6] = {
    //             key: "delete",
    //           };
    //         } else {
    //           this.fields[7] = {
    //             key: "delete",
    //           };
    //         }
  
    //         let table_index = this.table_header.findIndex((item) => item == "restore");
    //         this.table_header[table_index] = "delete";
    //       }
  
    //       if (!this.can("edit-forum")) {
    //         let index = this.fields.findIndex((item) => item.key == "edit");
    //         if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //             (item) => item == "edit"
    //           );
    //           this.table_header.splice(table_index, 1);
    //         }
    //       } else {
    //         if (!this.table_header.includes("edit")) {
    //           this.table_header.push("edit");
    //           this.fields[6] = {
    //             key: "edit",
    //           };
    //         }
    //       }
  
    //       if (!this.can("delete-forum")) {
    //         let index = this.fields.findIndex((item) => item.key == "delete");
    //         if (index !== -1) {
    //           this.fields.splice(index, 1);
    //           let table_index = this.table_header.findIndex(
    //             (item) => item == "delete"
    //           );
    //           this.table_header.splice(table_index, 1);
    //         }
    //       } else {
    //         if (!this.table_header.includes("delete")) {
    //           this.table_header.push("delete");
    //           this.fields[7] = {
    //             key: "delete",
    //           };
    //         }
    //       }
    //     }
    //   },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
    //   activeTab(v) {
    //     if (v) this.tabActive();
    //   },
    //   "status"(v) {
    //     if (v == 1) {
    //       this.fetchAllCommunity();
    //     } else {
    //       if (this.$route.name == 'add-forum') {
    //         this.form.community = [];
    //       } else if (this.$route.name == 'edit-forum') {
    //         this.form.community = this.oldForm.community;
    //       }
    //     }
    //   },
    },
    mounted() {
      if (this.$route.name == "add-forum-tool" || this.$route.name == "edit-forum-tool") {
        this.fetchForum();
        if (this.$route.name == "edit-forum-tool") {
          this.fetchForumTool(this.$route.params.id);
        }
        if (this.$route.name == "settings") {
          this.fetchForumTool(this.$route.params.id, this.$route.params.forum_id);
        }
      } else {
        this.activeTab = "all";
        if (this.$route.query.filter) {
            this.activeTab = this.$route.query.filter;
        }
        if (this.$route.query.page && this.$route.query.page != 1) {
            this.currentPage = parseInt(this.$route.query.page);
        } else {
            this.fetchData(this.activeTab);
        }
      }
    //   this.fields.map((item) => {
    //     this.table_header.push(item.key);
    //   });
    //   this.tabActive();
    },
  };